.poll-flex-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.poll-flex-row {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 8px;
  flex-direction: row;
}

/* TODO: remove custom flex, use framework */
.poll-flex-column {
  height: fit-content;
  width: 100%;
  /* display: flex; */
  align-items: baseline;
  gap: 8px;
  flex-direction: column;
}