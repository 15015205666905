.poll-select-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 2px 8px;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 700;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
  gap: 8px;
}

.poll-select-trigger:hover {
  cursor: pointer;
  background-color: #e3ebf6;
  border-color: #1b90ff;
}

.poll-select-trigger:focus {
  border-color: #1b90ff;
}

.poll-select-content {
  width: fit-content;
  background-color: white;
  border: 1px solid #1b90ff;
  border-radius: 4px;
  padding: 12px 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 28px;
}

.poll-select-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  padding: 4px 8px;
  transition: background-color 0.15s linear, color 0.15s linear;
}

.poll-select-item[data-state="checked"] {
  background-color: #1b90ff;
  color: white;
}

.poll-select-item:focus-visible {
  outline: none;
}

.poll-select-item:hover {
  background-color: #e3ebf6;
  cursor: pointer;
  color: unset;
}