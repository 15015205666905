.p-header-link {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background 0.2s ease-in-out;
}

.p-header-link:hover {
  background: #E6F6FF;
}

/* Avatar container */
.header-avatar-button {
  border: none;
  background-color: rgba(0,0,0,0);
  padding: 0;
  outline: none;
  cursor: pointer;
}

/* Avatar trigger */
.header-avatar-trigger {
  border: 1px solid #f1f2f2;
  transition: border 0.1 ease-in-out;
}
.header-avatar-trigger:hover {
  border: 1px solid #1b90ff;
}

/* Avatar container */
.header-avatar-container {
  padding: 16px 8px 19px 4px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid #f1f2f2;
  transition: border 0.1 ease-in-out;
  height: 38px;
}

.header-avatar-container:hover {
  border: 1px solid #1b90ff;
}