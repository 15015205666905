.app-card-overlay-container {
  display: relative;
  cursor: pointer;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  z-index: 10;
  margin-bottom: -40px;
  background-color: rgba(255, 255, 255, 0.7);
  transition: margin-bottom 0.5s, box-shadow 0.5s;
}

#po-app-card-trigger:hover > .app-card-overlay-container {
  margin-bottom: -100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px, rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
}

.po-app-card-description {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  overflow: auto;
  font-size: 12px;
  line-height: normal;
  margin-right: 4px;
  padding: 8px;
  font-weight: 500;
}

.po-app-card-description::-webkit-scrollbar {
  width: 10px;
}

.po-app-card-description::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3); 
  border-radius: 8px;
}

.po-app-card-description::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 5px rgba(46,168,244,1); 
}
