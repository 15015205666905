/* prevents document adjustments when dropdown is open */
html {
  overflow-y: scroll;
}

.ant-card-bordered {
  border-color: #D0D7DE !important;
}

.ant-divider-horizontal,
.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: #D0D7DE !important;
}

/* don't like the "no parking sign" on disabled buttons */
a.ant-typography.ant-typography-disabled {
  cursor: default !important;
}

.ant-btn[disabled] {
  cursor: default !important;
}

.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header>.arrow {
  cursor: default !important;
}

/* enables consistent positioning when using space-between on card body */
.ant-card-body::before {
  content: none !important;
}

.ant-card-body::after {
  content: none !important;
}

/* blurred backdrop */
.p-image-overlay {
  height: 48px;
  transform: translateY(-48px);
}

/* slightly transparent fallback */
.p-backdrop-blur {
  background-color: rgba(255, 255, 255, .9) !important;
}

.ant-timeline-item-tail {
  border-color: #D0D7DE !important;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .p-backdrop-blur {
    background-color: rgba(255, 255, 255, .5) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    backdrop-filter: blur(5px) !important;
  }
}